import React from "react";
import { getUnreadCampaignsAndCount } from "../../../main/store/campaignProspectStore/selectors";
import { unreadTabUpdateFilter } from "../../../main/store/uiStore/allUnreadPageView/actions";
import { getUnreadMessageFilter } from "../../../main/store/uiStore/allUnreadPageView/selectors";

const defaultValue = {
  unreadSelector: getUnreadCampaignsAndCount,
  filterSelector: getUnreadMessageFilter,
  updateFilter: unreadTabUpdateFilter,
  countUnread: (unreadFields: any[], selectedFilter: any) => {
    return (
      unreadFields.find(({ campaign }) => campaign.id === selectedFilter.value) || {}
    );
  },
  getOptions: (unreadFields: any[]) => {
    return [
      { label: "SMS Campaigns", value: 0 },
      ...unreadFields.map(({ campaign, count }) => ({
        label: campaign.name,
        value: campaign.id,
        archived: campaign.isArchived,
        count,
      })),
    ];
  },
};

export const ProspectListContext = React.createContext(defaultValue);
