import React from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";

import MessageDetail from "../MessageDetail";
import ProspectSlideOut from "../ProspectSlideOut";
import { ProspectCycleContext } from "../../../common/helpers/context";

import { getUnreadCampaignsAndCount } from "../../../main/store/campaignProspectStore/selectors";
import {
  unreadTabUpdateFilter,
  unreadTabUpdateSelectedItem,
} from "../../../main/store/uiStore/allUnreadPageView/actions";
import { getUnreadMessageFilter } from "../../../main/store/uiStore/allUnreadPageView/selectors";
import { setActiveCampaignProspect } from "../../../main/store/uiStore/campaignsPageDesktopView/campaignsList/filterData/actions";

import useOutsideClick from "module/common/hooks/useOutsideClick";
import { ProspectCard } from "../ProspectDetails";
import ProspectList from "../ProspectLists/ProspectList";
import { ProspectListContext } from "../ProspectLists/utils";
import { extraLogic } from "./UnreadMessagePage";
import { campaignProspectsToItemList } from "./utils";

const UnreadMessages = ({ children }) => {
  const dispatch = useDispatch();
  const {
    ref: outsideRef,
    isComponentVisible,
    setIsComponentVisible,
  } = useOutsideClick(false, extraLogic);

  const cycleAction = {
    action: (prospectId) => ({
      onClick: () => {
        dispatch(unreadTabUpdateSelectedItem(`${prospectId}`));
        dispatch(setActiveCampaignProspect(prospectId));
      },
    }),
  };

  return (
    <Row className="d-flex tab-content pageBody">
      <ProspectListContext.Provider value={prospectListContextValue}>
        <ProspectList
          colClassName="message-list pr-0"
          className="unread-list"
          prospectsToItemList={campaignProspectsToItemList}
        >
          {children}
        </ProspectList>
      </ProspectListContext.Provider>
      <Col xs={12} md={6} className="message-details">
        <ProspectCycleContext.Provider value={cycleAction}>
          <MessageDetail>
            <ProspectCard showProspectPopOver={() => setIsComponentVisible(true)} />
            {isComponentVisible ? (
              <ProspectSlideOut
                outsideRef={outsideRef}
                visible={isComponentVisible}
                onClose={() => setIsComponentVisible(false)}
              />
            ) : null}
          </MessageDetail>
        </ProspectCycleContext.Provider>
      </Col>
    </Row>
  );
};

const prospectListContextValue = {
  unreadSelector: getUnreadCampaignsAndCount,
  filterSelector: getUnreadMessageFilter,
  updateFilter: unreadTabUpdateFilter,
  countUnread: (unreadFields, selectedFilter) => {
    return (
      unreadFields.find(({ campaign }) => campaign.id === selectedFilter.value) || {}
    );
  },
  getOptions: (unreadFields) => {
    return [
      { label: "SMS Campaigns", value: 0 },
      ...unreadFields.map(({ campaign, count }) => ({
        label: campaign.name,
        value: campaign.id,
        archived: campaign.isArchived,
        count,
      })),
    ];
  },
};

export default UnreadMessages;
