import { Subscriptions } from "module/common/helpers/variables";
import AxiosInstance from "../../../common/utils/axiosConfig";

export const createPaymentInfo = (companyId: number, payload: any) =>
  AxiosInstance.post(`/companies/${companyId}/payment_methods/`, payload);

export const patchCompany = (id: any, payload: any) =>
  AxiosInstance.patch(`/companies/${id}/`, payload);

export const companySetInvitationCode = (id: number, payload: any) =>
  AxiosInstance.post(`/companies/${id}/invitation_code/`, payload);

export const companyProspectCount = (id: number) =>
  AxiosInstance.get(`companies/${id}/prospect_count/`);

export const postCancellation = (payload: any) => {
  return AxiosInstance.post(`/cancellations/`, payload);
};

export const companyIntegrationStatus = (id: number) =>
  AxiosInstance.get(`companies/${id}/integrations/`);

export const updateFeatureNotification = (id: number, payload: any) => {
  return AxiosInstance.patch(
    `/user-profiles/${id}/update_profile_feature_notification/`,
    payload
  );
};

export const patchUserProfile = (id: number, body: any) => {
  return AxiosInstance.patch(`/user-profiles/${id}/`, body);
};

export const deactivateUserProfile = (id: number, active: boolean) => {
  const body = {
    user: {
      isActive: active,
    },
  };
  return patchUserProfile(id, body);
};

export const deactivateSequenceDemo = (id: number) => {
  const body = {
    shouldShowSequenceDemo: false,
  };
  return patchUserProfile(id, body);
};

export const postBrandRequest = (payload: any) => {
  return AxiosInstance.post(`/phone/brand-requests/`, payload);
};

export const getBrandRequestInfo = () => {
  return AxiosInstance.get(`/phone/brand-requests/`);
};

export const postBrandRequestPurchase = (brandId: number, payload: any) => {
  return AxiosInstance.post(`/phone/brand-requests/${brandId}/purchase/`, payload);
};

export const getBrandStatus = () => {
  return AxiosInstance.get(`/phone/brands/`);
};

export interface PatchBrandPayload {
  website: string;
}
export const patchBrandInfo = (brandId: string, payload: PatchBrandPayload) => {
  return AxiosInstance.patch(`/phone/brands/${brandId}/`, payload);
};

export interface PatchSmsRequestPayload {
  planRequested?: Subscriptions;
  userProfileSubmittedBy?: number;
  action?: "purchase";
}

export const patchSmsSubscriptionRequest = (
  companyId: number,
  payload: PatchSmsRequestPayload
) => {
  return AxiosInstance.patch(
    `/companies/${companyId}/latest_sms_subscription_request/`,
    payload
  );
};

export const getSmsSubscriptionRequest = (companyId: number) => {
  return AxiosInstance.get(`/companies/${companyId}/latest_sms_subscription_request/`);
};

export interface PutOptInPayload {
  userProfileSubmittedBy?: number;
  optInMethods: string[];
  website: string;
  additionalInformation?: string;
}

export const putOptInAttestation = (id: number, payload: PutOptInPayload) => {
  return AxiosInstance.put(`/companies/${id}/sms_opt_in_attestation/`, payload);
};

export const getOptInOptions = (id: number) => {
  return AxiosInstance.options(`/companies/${id}/sms_opt_in_attestation/`);
};

export const getCompanyMonthlyHistory = (companyId: number) => {
  return AxiosInstance.get(`/companies/${companyId}/monthly_history/`);
};

export const getCompanyDailyHistory = (
  companyId: number,
  month: number,
  year: number
) => {
  return AxiosInstance.get(
    `/companies/${companyId}/daily_history/?month=${month}&year=${year}`
  );
};
