import { produce } from "immer";

import {
  UNREAD_TAB_UPDATE_SELECTED_ITEM,
  UNREAD_TAB_UPDATE_FILTER,
  UNREAD_TAB_RESET_STATE,
  UNREAD_TAB_POLL_MESSAGE_COUNT,
  UNREAD_TAB_POLL_MESSAGES,
} from "./actions";

export const path = ["uiStore", "allUnreadPageView"];

const initialState = {
  selectedItemId: `00`,
  filter: { label: "SMS Campaigns", value: 0 },
  polling: {
    fetchCount: true,
    fetchMessages: false,
  },
};

const reducer = produce((base: any, action: any) => {
  switch (action.type) {
    case UNREAD_TAB_UPDATE_SELECTED_ITEM: {
      base.selectedItemId = action.payload;
      break;
    }
    case UNREAD_TAB_UPDATE_FILTER: {
      base.filter = action.payload;
      break;
    }
    case UNREAD_TAB_RESET_STATE: {
      return initialState;
    }
    case UNREAD_TAB_POLL_MESSAGE_COUNT: {
      base.polling.fetchCount = action.payload;
      break;
    }
    case UNREAD_TAB_POLL_MESSAGES: {
      base.polling.fetchMessages = action.payload;
      break;
    }
  }
}, initialState);

export default reducer;
